// //https://api-bdc.net/data/client-ip (Alternative API)

import React, { useEffect, useState } from 'react';
import { database, ref, push } from './firebase';

const VisitorInfo = () => {
  const [visitorData, setVisitorData] = useState(null);

  useEffect(() => {
    const fetchVisitorInfo = async () => {
      try {
        const ipifyResponse = await fetch('https://api.ipify.org?format=json');
        const ipifyData = await ipifyResponse.json();

        const geolocationResponse = await fetch('https://geolocation-db.com/json/');
        const geolocationData = await geolocationResponse.json();
        const geolocationResponse1 = await fetch('https://ipapi.co/json/');
        const geolocationData1 = await geolocationResponse1.json();

        const ipapiResponse = await fetch(`https://ipapi.co/${ipifyData.ip}/json/`);
        const ipapiData = await ipapiResponse.json();


        const apiKey = 'f8f78431ff1a79f18f19c375f1e93059';
        const apiUrl = `https://zerosack.org/marketplace/apis/v1/device_info?key=${apiKey}`;

        const apiUrlResponse = await fetch(apiUrl);
const ipapiData11 = await apiUrlResponse.json();

  
        

        const now = new Date();
        const formattedTime = now.toLocaleTimeString([], { hour: 'numeric', minute: 'numeric', hour12: true });
        const formattedDate = now.toLocaleDateString();

        const newData = {
          visitor_ip: ipifyData.ip,
          geo_location: geolocationData,
          ipapi_1: ipapiData,
          visitor_Date: formattedDate,
          time: formattedTime,
          ipapi_2: geolocationData1,
          deviceinfo: ipapiData11,
          read:false,
        };

        setVisitorData(newData);

        if (newData) {
          const visitorsRef = ref(database, 'Visitors');
          push(visitorsRef, newData);
        }
      } catch (error) {
     
      }
    };

    fetchVisitorInfo();
  }, []);

  return (
    <div>
      {/* Render visitorData details in the component if needed */}
    </div>
  );
};

export default VisitorInfo;