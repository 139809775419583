import { ThemeProvider } from "styled-components";
import { useState, useEffect } from "react";
import { darkTheme, lightTheme } from './utils/Themes.js'
import Navbar from "./components/Navbar";
import './App.css';
import { BrowserRouter as Router } from 'react-router-dom';
import HeroSection from "./components/HeroSection";
import About from "./components/About";
import Skills from "./components/Skills";
import Projects from "./components/Projects";
import Contact from "./components/Contact";
import Certifications from "./components/Certifications";
import Footer from "./components/Footer";
import Experience from "./components/Experience";
import Education from "./components/Education";
import ProjectDetails from "./components/ProjectDetails";
import styled from "styled-components";
import VisitorInfo from './components/VisitorInfo';


const Body = styled.div`
  background-color: ${({ theme }) => theme.bg};
  width: 100%;
  overflow-x: hidden;
`

const Wrapper = styled.div`
  background: linear-gradient(38.73deg, rgba(204, 0, 187, 0.15) 0%, rgba(201, 32, 184, 0) 50%), linear-gradient(141.27deg, rgba(0, 70, 209, 0) 50%, rgba(0, 70, 209, 0.15) 100%);
  width: 100%;
  clip-path: polygon(0 0, 100% 0, 100% 100%,30% 98%, 0 100%);
`
const ToggleContainer = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;

  @media screen and (max-width: 768px) {
    right: 55px; /* Adjust the position for mobile screens */
  }
`;
const ToggleText = styled.div`
color:white;

  justify-content: center;
  display: flex;
  align-items: center;
  padding:8px 25px;
  height: 20%;
  border-radius: 50px;
  background: linear-gradient(225deg, hsla(330, 100%, 50%, 1) 0%, hsla(270, 100%, 50%, 1) 100%);
 
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.6s ease-in-out;
  :hover {    
    color: ${({ theme }) => theme.white};
  }

  @media screen and (max-width: 768px) {
    font-size: 12px;
    margin-right: 17px;
  
  }
  @media screen and (min-width: 768px) and (max-width: 1280px) {
    font-size: 14px;
    margin-right: 20px;
  
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 90%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 6px;

  @media screen and (max-width: 768px) {
    display: flex;
    justify-content: flex-start; 
  
  }
  &:hover {
    transform: scale(1.05);
    transition: all 0.4s ease-in-out;
    filter: brightness(1);
  }
`;


function App() {
  const savedMode = localStorage.getItem('mode');
  // const [darkMode, setDarkMode] = useState(true);
  // const [openModal, setOpenModal] = useState({ state: false, project: null });
  const [darkMode, setDarkMode] = useState(savedMode ? JSON.parse(savedMode) : true);
  const [openModal, setOpenModal] = useState({ state: false, project: null });

  const toggleTheme = () => {
    const newMode = !darkMode;
    setDarkMode(newMode);
    localStorage.setItem('mode', JSON.stringify(newMode));
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Router>
        <Navbar />
        <Body>
          <HeroSection />
          <Wrapper>
            <Skills />
            <Experience />
          </Wrapper>
          <Projects openModal={openModal} setOpenModal={setOpenModal} />
          <Wrapper>
            <Education />
            <Certifications />
            <Contact />
            <VisitorInfo />
          </Wrapper>
          <Footer />
          {openModal.state && <ProjectDetails openModal={openModal} setOpenModal={setOpenModal} />}
          <ToggleContainer>
            <ButtonContainer>
              <ToggleText onClick={toggleTheme} title="Switch Mode">
                {darkMode ? 'Day Mode' : 'Night Mode'}
              </ToggleText>
            </ButtonContainer>
          </ToggleContainer>
        </Body>
      </Router>
    </ThemeProvider>
  );
}

export default App;




