import React from 'react'
import styled from 'styled-components'
import { certifications } from '../../data/constants'


const Document = styled.img`
  display: flex; /* Changed from 'none' to 'flex' */
  height:70px;
  width: fit-content;
  background-color: #000;
  border-radius: 10px;
  opacity: 0.8; /* Adjusted opacity to make it visible by default */
  position: relative;
  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }

  @media only screen and (max-width: 768px) {
    height: 55px;
    width: auto;
  }
`;
const CertificationsWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
`


const DocumentWrapper = styled.div`
  position: relative;
  display: inline-block;
`;

const CardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const Label = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #606060;
  font-weight: 500;
 
  @media only screen and (max-width: 768px) {
    top: 50%;
  
  }
`;

const Description = styled.div`
    width: 100%;
    font-size: 15px;
    font-weight: 500;
    color: ${({ theme }) => theme.text_primary + 99};
    margin-bottom: 10px;
    @media only screen and (max-width: 768px){
        font-size: 12px;
    }
`

const Span = styled.span`
overflow: hidden;
display: -webkit-box;
max-width: 100%;
-webkit-line-clamp: 4;
-webkit-box-orient: vertical;
text-overflow: ellipsis;
`

const Card = styled.div`
    width: 650px;
    border-radius: 10px;
    border-radius: 10px;
    box-shadow: rgba(23, 92, 230, 0.15) 0px 4px 24px;
    padding: 12px 16px;
    justify-content: space-between;
    position: relative;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    gap: 12px;
    transition: all 0.3s ease-in-out;
    border: 0.1px solid #854CE6;
    
    &:hover{
        box-shadow: 0 0 50px 4px rgba(148, 87, 235, 0.9);


        transform: translateY(-5px);
    }
    @media only screen and (max-width: 768px){
        padding: 10px;
        gap: 8px;
        max-width: 320px;
        margin-right: 23px;
        

        
    }

    &:hover ${Document}{
        display: flex;
    }

    &:hover ${Span}{
        overflow: visible;
        -webkit-line-clamp: unset;

    }

    border: 2px solid #854CE6;
    box-shadow: 0 0 20px 2px rgba(148, 87, 235, 0.7);
`

const Top = styled.div`
    width: 100%;
    display: flex;
    gap: 12px
`

const Image = styled.img`
    height: 50px;
    background-color: #000;
    border-radius: 10px;
    margin-top: 4px;
    @media only screen and (max-width: 768px){
        height: 40px;
    }
`

const Body = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column; 
`


const Role = styled.div`
    font-size: 16px;
    font-weight: 600;
    color: ${({ theme }) => theme.text_primary + 99};
    @media only screen and (max-width: 768px){
        font-size: 12px;
    
    }
`

const Company = styled.div`
   
    font-size: 18px;
    font-weight: 650;
    color: ${({ theme }) => theme.text_primary + 99};
    @media only screen and (max-width: 768px){
        font-size: 14px;
    }
`

const Date = styled.div`
    font-size: 12px;
    font-weight: 400;
    color: ${({ theme }) => theme.text_secondary + 80};
    @media only screen and (max-width: 768px){
        font-size: 10px;
    }
`


const Skills = styled.div`
    width: 100%;
    display: block;
    gap: 12px;
    margin-top: -10px;
`

const ItemWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
`

const Skill = styled.div`
    font-size: 15px;
    font-weight: 500;
    color: ${({ theme }) => theme.text_primary + 99};
    @media only screen and (max-width: 768px){
        font-size: 12px;
    }
`


const CertificationsCard = ({ certifications }) => {
    return (
              <Card>
            <Top>
                <Image src={certifications.img} />
          
                <Body>
                <Company>Issuer : {certifications.company}</Company>
                    <Role>{certifications.role}</Role>
                    
                    
                    
                </Body>
                
                {certifications.doc && (
  <DocumentWrapper>
    <a href={certifications.doc} target="_blank" rel="noopener noreferrer">
      <Document src={certifications.doc} />
      <Label>Click to expand</Label>
    </a>
  </DocumentWrapper>
)}

            </Top>
            <Description>
                {certifications?.desc &&
                    <Span>{certifications?.desc}</Span>

                }
                {certifications?.skills &&
                    <>
                        <br />
                        <Skills>
                            <b>Skills:</b>
                            <ItemWrapper>
                                {certifications?.skills?.map((skill, index) => (
                                    <Skill>• {skill}</Skill>
                                ))}
                            </ItemWrapper>
                        </Skills>
                    </>
                }
            </Description>
           
        </Card>
    )
}

export default CertificationsCard




