import React from 'react'
import { Nav, NavLink, NavbarContainer, Span, NavLogo, NavItems, GitHubButton, ButtonContainer, MobileIcon, MobileMenu, MobileNavLogo, MobileLink } from './NavbarStyledComponent'
import { DiCssdeck } from 'react-icons/di';
import { FaBars } from 'react-icons/fa';
import { Bio } from '../../data/constants';
import { Close, CloseRounded } from '@mui/icons-material';
import { useTheme } from 'styled-components';
import { Link } from 'react-router-dom';
import customLogo from '../../images/favicon.ico';


const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const theme = useTheme()
  return (
    <Nav>
      <NavbarContainer>
        <NavLogo to='/'>
          <a style={{ display: "flex", alignItems: "center", color: "white", marginBottom: '20;', cursor: 'pointer' }}>
            <img src={customLogo} alt="logo" style={{ width: '30px', height: '30px', borderRadius: '20%',marginRight: '5px' }} />
           
             <Span>Portfolio</Span>
          </a>
        </NavLogo>
        <MobileIcon>
          <FaBars onClick={() => {
            setIsOpen(!isOpen)
          }} />
        </MobileIcon>
        <NavItems>
        
          <NavLink href="/">Home</NavLink>
          <NavLink href="#about">About</NavLink>
          <NavLink href='#skills'>Skills</NavLink>
          <NavLink href='#experience'>Experience</NavLink>
          <NavLink href='#projects'>Projects</NavLink>
          <NavLink href='#education'>Education</NavLink>
          <NavLink href='#certifications'>Certifications</NavLink>
          <NavLink href='#contact'>Contact</NavLink>
        </NavItems>
        <ButtonContainer>
          <GitHubButton href={Bio.alllinks} target="_blank" title="Click to view all my Links">Visit My Links</GitHubButton>
        </ButtonContainer>
        
        {
          isOpen &&
          <MobileMenu isOpen={isOpen}>
            <MobileLink href="#about" onClick={() => {
              setIsOpen(!isOpen)
            }}>About</MobileLink>
            <MobileLink href='#skills' onClick={() => {
              setIsOpen(!isOpen)
            }}>Skills</MobileLink>
            <MobileLink href='#experience' onClick={() => {
              setIsOpen(!isOpen)
            }}>Experience</MobileLink>
            <MobileLink href='#projects' onClick={() => {
              setIsOpen(!isOpen)
            }}>Projects</MobileLink>
            <MobileLink href='#education' onClick={() => {
              setIsOpen(!isOpen)
            }}>Education</MobileLink>
              <MobileLink href='#home' onClick={() => {
              setIsOpen(!isOpen)
            }}>Home</MobileLink>
              <MobileLink href='#certifications' onClick={() => {
              setIsOpen(!isOpen)
            }}>Certifications</MobileLink>
              <MobileLink href='#contact' onClick={() => {
              setIsOpen(!isOpen)
            }}>Contact</MobileLink>
            <GitHubButton
  style={{
    padding: '10px 16px',
    background: 'linear-gradient(225deg, hsla(330, 100%, 50%, 1) 0%, hsla(270, 100%, 50%, 1) 100%)',
  
    color: 'white',
    width: 'max-content',
    textAlign: 'left',
    height:'30px',
   
  }}
  href={Bio.alllinks}
  target="_blank"
>
  Visit My Links
</GitHubButton>

          </MobileMenu>
        }
      </NavbarContainer>
    </Nav>
  )
}

export default Navbar


