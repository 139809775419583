import { initializeApp } from 'firebase/app';
import { getDatabase, ref, push } from 'firebase/database';

const firebaseConfig = {
    apiKey: "AIzaSyCdHugSMz6T364TtBZVWZo384Yjp1Stfi8",
    authDomain: "portfolioimages-fdfbd.firebaseapp.com",
    databaseURL: "https://portfolioimages-fdfbd-default-rtdb.firebaseio.com",
    projectId: "portfolioimages-fdfbd",
    storageBucket: "portfolioimages-fdfbd.appspot.com",
    messagingSenderId: "118024864380",
    appId: "1:118024864380:web:0248689206466ab43bfff9",
    measurementId: "G-S95ZV70D2G"
  };
  const app = initializeApp(firebaseConfig);
  const database = getDatabase(app);
  
  export { database, ref, push };